var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-5 mt-4" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-row", { staticClass: "h5" }, [
                _vm._v(" " + _vm._s(_vm.driverName) + " "),
              ]),
              _c("b-row", [_vm._v(" " + _vm._s(_vm.plan) + " ")]),
              _c("b-row", [
                _vm._v(
                  " " +
                    _vm._s(_vm.licensePlate) +
                    " - " +
                    _vm._s(_vm.carModel) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn btn-success no-print",
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.printPage()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("payments.buttons.printNegotiation")) +
                      " "
                  ),
                ]
              ),
              _c("img", {
                attrs: {
                  src: "/img/brand/logo.svg",
                  width: "150",
                  height: "50",
                  alt: "Kovi Rental",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "w-75 mt-5", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            {
              staticClass:
                "bg-danger p-2 justify-content-md-center print-exact",
            },
            [_c("h4", { staticClass: "text-white mb-0" }, [_vm._v("Resumo")])]
          ),
          _c("b-col", { staticClass: "p-0 mb-5 mt-4" }, [
            _c("dl", { staticClass: "row" }, [
              _c("dt", { staticClass: "col-sm-4 font-weight-bolder" }, [
                _vm._v(_vm._s(_vm.$t("payments.labels.startDate")) + ":"),
              ]),
              _c("dd", { staticClass: "col-sm-8" }, [
                _vm._v(_vm._s(_vm._f("moment")(_vm.startDate, "DD/MM/YYYY"))),
              ]),
            ]),
            _c("hr"),
            _c("dl", { staticClass: "row" }, [
              _c("dt", { staticClass: "col-sm-4 font-weight-bolder" }, [
                _vm._v(
                  _vm._s(_vm.$t("payments.labels.totalInstallments")) + ":"
                ),
              ]),
              _c("dd", { staticClass: "col-sm-8" }, [
                _vm._v(_vm._s(_vm.installments) + "x"),
              ]),
            ]),
            _c("hr"),
            _c("dl", { staticClass: "row" }, [
              _c("dt", { staticClass: "col-sm-4 font-weight-bolder" }, [
                _vm._v(_vm._s(_vm.$t("payments.labels.paymentMethod")) + ":"),
              ]),
              _c("dd", { staticClass: "col-sm-8" }, [
                _vm._v(_vm._s(_vm.getPaymentMethodLabel())),
              ]),
            ]),
            _c("hr"),
            _c("dl", { staticClass: "row" }, [
              _c("dt", { staticClass: "col-sm-4 font-weight-bolder" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("payments.labels.totalNegotiationTransaction")
                  ) + ":"
                ),
              ]),
              _c("dd", { staticClass: "col-sm-8" }, [
                _vm._v(
                  _vm._s(
                    _vm.setPriceFormat(
                      _vm.$t("payments.labels.currency"),
                      _vm.negotiationResume.amount_reference
                    )
                  )
                ),
              ]),
            ]),
            _c("hr"),
            _c("dl", { staticClass: "row" }, [
              _c("dt", { staticClass: "col-sm-4 font-weight-bolder" }, [
                _vm._v(_vm._s(_vm.$t("payments.labels.totalInterest")) + ":"),
              ]),
              _c("dd", { staticClass: "col-sm-8" }, [
                _vm._v(
                  _vm._s(
                    _vm.setPriceFormat(
                      _vm.$t("payments.labels.currency"),
                      _vm.negotiationResume.interestTotal
                    )
                  )
                ),
              ]),
            ]),
            _c("hr"),
            _c("dl", { staticClass: "row py-3" }, [
              _c(
                "dt",
                { staticClass: "col-sm-4 text-hei font-weight-bolder lead" },
                [
                  _vm._v(
                    _vm._s(_vm.$t("payments.labels.totalNegotiation")) + ":"
                  ),
                ]
              ),
              _c("dd", { staticClass: "col-sm-8 font-weight-bolder lead" }, [
                _vm._v(
                  _vm._s(
                    _vm.setPriceFormat(
                      _vm.$t("payments.labels.currency"),
                      _vm.negotiationResume.amount
                    )
                  )
                ),
              ]),
            ]),
            _c("dl", { staticClass: "row" }, [
              _c(
                "small",
                {
                  staticClass: "text-danger",
                  style: {
                    visibility: _vm.isCreditCard ? "visible" : "hidden",
                  },
                },
                [
                  _vm._v(
                    " * " +
                      _vm._s(
                        _vm.$t("payments.labels.transactionFeeInformation")
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "b-row",
            {
              staticClass:
                "bg-danger p-2 justify-content-md-center print-exact",
            },
            [
              _c("h4", { staticClass: "text-white mb-0" }, [
                _vm._v("Histórico de Faturas Pendentes"),
              ]),
            ]
          ),
          _c(
            "b-row",
            { staticClass: "justify-content-md-center" },
            [
              _c("b-table", {
                staticClass: "w-100",
                attrs: {
                  items: this.transactions,
                  fields: _vm.fields,
                  responsive: "sm",
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(kind)",
                    fn: function (data) {
                      return [
                        _c(
                          "big-badge",
                          {
                            staticClass: "print-exact",
                            attrs: {
                              variant: _vm.setKindFormat(data.item.kind),
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.setKindLabel(data.item.kind)) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(overdue_limit)",
                    fn: function (data) {
                      return [
                        _c("span", { staticClass: "text-uppercase" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  data.item.overdue_limit,
                                  "DD/MM/YYYY"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "cell(amount)",
                    fn: function (data) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.setPriceFormat(
                                _vm.$t("payments.labels.currency"),
                                data.item.amount
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("b-row", { staticClass: "justify-content-md-center mt-5" }, [
            _c("p", { staticClass: "p-3 w-75 bg-orange lead print-exact" }, [
              _vm._v(
                " Motora! Importante lembrar que o veículo passará por um processo de diagnóstico que poderá gerar novas cobranças em sua carteira. Fique de olho! "
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }