<template>
  <div class="mx-5 mt-4">
    <b-row>
      <b-col>
        <b-row class="h5"> {{ driverName }} </b-row>
        <b-row> {{ plan }} </b-row>
        <b-row> {{ licensePlate }} - {{ carModel }} </b-row>
      </b-col>
      <b-col class="text-right">
        <b-button variant="primary" class="btn btn-success no-print" @click="printPage()">
          {{ $t('payments.buttons.printNegotiation') }}
        </b-button>
        <img src="/img/brand/logo.svg" width="150" height="50" alt="Kovi Rental" />
      </b-col>
    </b-row>
    <b-container fluid class="w-75 mt-5">
      <b-row class="bg-danger p-2 justify-content-md-center print-exact">
        <h4 class="text-white mb-0">Resumo</h4>
      </b-row>

      <b-col class="p-0 mb-5 mt-4">
        <dl class="row">
          <dt class="col-sm-4 font-weight-bolder">{{ $t('payments.labels.startDate') }}:</dt>
          <dd class="col-sm-8">{{ startDate | moment('DD/MM/YYYY') }}</dd>
        </dl>
        <hr />
        <dl class="row">
          <dt class="col-sm-4 font-weight-bolder">{{ $t('payments.labels.totalInstallments') }}:</dt>
          <dd class="col-sm-8">{{ installments }}x</dd>
        </dl>
        <hr />
        <dl class="row">
          <dt class="col-sm-4 font-weight-bolder">{{ $t('payments.labels.paymentMethod') }}:</dt>
          <dd class="col-sm-8">{{ getPaymentMethodLabel() }}</dd>
        </dl>
        <hr />
        <dl class="row">
          <dt class="col-sm-4 font-weight-bolder">{{ $t('payments.labels.totalNegotiationTransaction') }}:</dt>
          <dd class="col-sm-8">{{ setPriceFormat($t('payments.labels.currency'), negotiationResume.amount_reference) }}</dd>
        </dl>
        <hr />
        <dl class="row">
          <dt class="col-sm-4 font-weight-bolder">{{ $t('payments.labels.totalInterest') }}:</dt>
          <dd class="col-sm-8">{{ setPriceFormat($t('payments.labels.currency'), negotiationResume.interestTotal) }}</dd>
        </dl>
        <hr>
        <dl class="row py-3">
          <dt class="col-sm-4 text-hei font-weight-bolder lead">{{ $t('payments.labels.totalNegotiation') }}:</dt>
          <dd class="col-sm-8 font-weight-bolder lead">{{ setPriceFormat($t('payments.labels.currency'), negotiationResume.amount) }}</dd>
        </dl>
        <dl class="row">
          <small class="text-danger " :style="{ visibility: isCreditCard ? 'visible' : 'hidden'}">
              * {{ $t('payments.labels.transactionFeeInformation') }}
          </small>
        </dl>
      </b-col>

      <b-row class="bg-danger p-2 justify-content-md-center print-exact">
        <h4 class="text-white mb-0">Histórico de Faturas Pendentes</h4>
      </b-row>

      <b-row class="justify-content-md-center">
        <b-table :items="this.transactions" :fields="fields" responsive="sm" class="w-100">
          <template v-slot:cell(kind)="data">
            <big-badge :variant="setKindFormat(data.item.kind)" class="print-exact">
              {{ setKindLabel(data.item.kind) }}
            </big-badge>
          </template>

          <template v-slot:cell(overdue_limit)="data">
            <span class="text-uppercase">
              {{ data.item.overdue_limit | moment('DD/MM/YYYY') }}
            </span>
          </template>

          <template v-slot:cell(amount)="data">
            {{ setPriceFormat($t('payments.labels.currency'), data.item.amount) }}
          </template>
        </b-table>
      </b-row>

      <b-row class="justify-content-md-center mt-5">
        <p class="p-3 w-75 bg-orange lead print-exact">
          Motora! Importante lembrar que o veículo passará por um processo de diagnóstico que poderá gerar novas cobranças em sua carteira. Fique de olho!
        </p>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { formatPrice } from '@utils/numbers';
import { formatKind, parseKind } from '@utils/wallet';
import BigBadge from '@components/shared/big-badge';
export default {
  name: 'DriverPrintNegotiation',
  components: {
    BigBadge
  },
  page: {
    title: 'Impressão negociação',
  },
  data() {
    return {
      driverName: '',
      licensePlate: '',
      carModel: '',
      plan: '',
      installments: 0,
      startDate: '',
      paymentMethod: '',
      transactions: [],
      negotiationResume: null,
      fields: [
        { key: 'kind', label: this.$t('payments.tables.kind') },
        { key: 'description', label: this.$t('payments.tables.description') },
        { key: 'amount', label: this.$t('payments.tables.amount') },
        { key: 'overdue_limit', label: this.$t('payments.tables.overdue_limit') },
      ],
    };
  },
  mounted() {
    const negotiationData = this.getPrintInfo();
    if (!negotiationData) {
      this.$router.push({ path: '/dashboard' });
    }

    this.transactions = negotiationData.transactions;
    this.negotiationResume = negotiationData.negotiation_resume;
    this.driverName = negotiationData.driverName;
    this.licensePlate = negotiationData.license_plate;
    this.carModel = negotiationData.model;
    this.plan = negotiationData.plan;
    this.startDate = negotiationData.start_date;
    this.paymentMethod = negotiationData.payment_method;
    this.installments = negotiationData.installments;
  },
  methods: {
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
    setKindLabel: parseKind,
    setKindFormat: formatKind,
    printPage() {
      window.print();
    },
    getPrintInfo() {
      const info = JSON.parse(localStorage.getItem('kovi.printNegotiation'));
      localStorage.removeItem('kovi.printNegotiation');
      return info;
    },
    getPaymentMethodLabel: function () {
      switch (this.paymentMethod) {
        case 'boleto':
          return this.$t('payments.enumMethod.billet');
        case 'oxxo':
          return this.$t('payments.enumMethod.oxxo');
        case 'credit_card':
          return this.$t('payments.enumMethod.creditCard');
        case 'pix':
          return this.$t('payments.enumMethod.pix');
        default:
          return ''
      }
    },
    isCreditCard: function () {
      return this.paymentMethod === 'credit_card'
    }
  },
};
</script>

<style scoped>
@media print {
  .no-print {
    display: none;
  }

  .print-exact {
    -webkit-print-color-adjust: exact;
  }
}
</style>
